<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="9">
                <v-card flat class="mr-3" :loading="loading">
                    <v-card-title>{{ pageTitle }}</v-card-title>
                    <v-card-subtitle>{{ pageSubtitle }}</v-card-subtitle>
                    <v-card-text v-if="store != null">
                        <v-form ref="form">
                            <v-row>
                                <v-col cols="6">
                                    <div class="subheading grey--text">
                                        General Information
                                    </div>
                                    <v-select v-model="store.typeId" :items="storeTypes" item-value="id" item-text="name" 
                                        style="max-width:300px" label="Type" :rules="[ rules.required, rules.isStoreType ]"></v-select>

                                    <v-row no-gutters>
                                        <v-text-field v-model="store.name" label="Store name" :rules="[ rules.required ]"></v-text-field>
                                        <v-text-field v-model="store.wintuxStoreId" label="Wintux store id" :rules="[ rules.required ]"></v-text-field>
                                        <v-text-field v-model="store.phone" label="Phone" v-mask="'(###) ###-####'" type="tel" :rules="[ rules.required, rules.isPhone ]"></v-text-field>
                                        <v-text-field v-model="store.cel" label="Cel. phone" v-mask="'(###) ###-####'" type="tel" :rules="[ rules.isPhone ]"></v-text-field>
                                        <v-text-field v-model="store.email" label="Email" type="email" :rules="[ rules.required, rules.isEmail ]"></v-text-field>
                                        <v-text-field v-model="store.terminalQty" label="Store code max use" :rules="[ rules.required, rules.isInteger ]"></v-text-field>
                                        <v-text-field v-model="store.defaultPickupTime" label="Default pickup time" hint="in 24 hours format (hh:mm)" 
                                            v-mask="'##:##'" :rules="[ rules.required, rules.isTime ]"></v-text-field>
                                        <v-text-field v-model="store.tax" label="Tax" hint="in decimal form" :rules="[ rules.required, rules.isTax ]"></v-text-field>
                                    </v-row>

                                    <v-switch v-model="store.active" label="Active" right></v-switch>

                                    <div class="subheading grey--text mt-8">
                                        App Security
                                    </div>
                                    <v-text-field v-model="store.authKey" label="Rental app authorization key"></v-text-field>
                                    <v-alert type="warning" dense border="left"><strong>WARNING</strong>: If you're not sure what is this for, please do not change it. If this value is not correct this store won't work on the rental app.</v-alert>

                                </v-col>
                                <v-col cols="6">
                                    <div class="subheading grey--text">
                                        Email Notifications
                                    </div>

                                    <div class="caption">Send every day codes to this email addresses</div>

                                    <v-simple-table class="mt-3" fixed-header height="275px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">Email address</th>
                                                    <th class="text-right">
                                                        <v-btn depressed small @click="addEmailDialog = true">
                                                            <v-icon left>mdi-plus</v-icon>
                                                            New
                                                        </v-btn>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="email in store.codesToEmail" :key="email">
                                                    <td>{{ email }}</td>
                                                    <td class="text-right">
                                                        <v-btn icon color="red" @click="openEmailWarning( email )"><v-icon>mdi-delete-outline</v-icon></v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>

                                    <div class="subheading grey--text mt-5">
                                        Text Message Notifications
                                    </div>

                                    <div class="caption">Send every day codes to this phone numbers</div>

                                    <v-simple-table class="mt-3" fixed-header height="275px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-lef">Phone number</th>
                                                    <th class="text-right">
                                                        <v-btn depressed small @click="addPhoneDialog = true">
                                                            <v-icon left>mdi-plus</v-icon>
                                                            New
                                                        </v-btn>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="phone in store.codesToPhone" :key="phone">
                                                    <td>{{ toPhoneNumber( phone ) }}</td>
                                                    <td class="text-right">
                                                        <v-btn icon color="red" @click="openPhoneWarning( phone )"><v-icon>mdi-delete-outline</v-icon></v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-form>

                        <div class="text-right mt-7">
                            <v-btn depressed color="grey darken-4" class="white--text" @click="save" :loading="saving">Save</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="3">
                <v-card flat>
                    <v-card-title class="headline font-weight-light grey--text text--darken-4">History</v-card-title>
                    <v-card-text class="px-0">
                        <history obj-type="store" :id="storeId"  :showForm="false" ref="history" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="addEmailDialog" width="500">
            <v-card>
                <v-card-title>Add new email</v-card-title>
                <v-card-subtitle>This new email will be receiving this store codes every day</v-card-subtitle>
                <v-card-text>
                    <v-form ref="emailForm">
                        <v-text-field v-model="newEmail" type="email" placeholder="john.doe@l5a.com" outlined 
                            :rules="[ rules.isEmail, emailExist ]" @keydown.enter.prevent="addToEmailList" autofocus></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="closeEmailDialog">Cancel</v-btn>
                    <v-btn depressed color="grey darken-4" class="white--text" @click="addToEmailList">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="removeEmailDialog" width="500">
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    <p>Are you sure you want to remove {{ emailToBeRemoved }} from the codes notification?</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="removeEmailDialog = false" style="text-transform:none">Cancel</v-btn>
                    <v-btn depressed color="error" class="white--text" @click="removeEmail" style="text-transform:none">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="addPhoneDialog" width="500">
            <v-card>
                <v-card-title>Add new phone number</v-card-title>
                <v-card-subtitle>This new phone number will be receiving this store codes every day</v-card-subtitle>
                <v-card-text>
                    <v-form ref="phoneForm">
                        <v-text-field v-model="newPhone" type="tel" placeholder="(999) 999-9999" v-mask="'(###) ###-####'" 
                            outlined :rules="[ rules.isPhone, phoneExist ]" @keydown.enter.prevent="addToPhoneList" autofocus></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="closePhoneDialog">Cancel</v-btn>
                    <v-btn depressed color="grey darken-4" class="white--text" @click="addToPhoneList">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="removePhoneDialog" width="500">
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    <p>Are you sure you want to remove {{ phoneToBeRemoved }} from the codes notification?</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="removePhoneDialog = false" style="text-transform:none">Cancel</v-btn>
                    <v-btn depressed color="error" class="white--text" @click="removePhone" style="text-transform:none">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { API } from '@/inc/api';
import { _st } from '@/softech';
import { mask } from 'vue-the-mask';
import History from '@/components/History.vue';

export default {
    props: {
        storeId: { type: [ Number, String ], default: null }
    },
    data: () => ({
        store               : null,
        storeTypes          : [],
        loading             : false,
        saving              : false,

        addEmailDialog      : false,
        removeEmailDialog   : false,
        addPhoneDialog      : false,
        removePhoneDialog   : false,
        newEmail            : '',
        newPhone            : '',
        emailToBeRemoved    : '',
        phoneToBeRemoved    : '',

        // form validation rules
        rules               : {
            required        : v => !!v || 'required',
            isStoreType     : v => _st.isInteger( v ) || 'store type must be valid',
            isEmail         : v => _st.isEmail( v ) || 'must be a valid email address',
            isPhone         : v => _st.isPhone( v ) || 'must be a valid phone number',
            isInteger       : v => _st.isInteger( v ) && v >= 0 || 'must be a positive integer value',
            isTime          : v => !_st.isNUE( v ) && v.split(':').length >= 2 || 'must be a valid time',
            isTax           : v => _st.isNumeric( v ) && v < 1 && v >= 0 || 'must be a valid percentage decimal value',
        }
    }),
    computed: {
        pageTitle() {
            if( _st.isNUE( this.storeId ) || this.storeId == 'new' ) {
                return 'Create new store';
            }
            else if( _st.isNUE( this.store ) ) {
                return 'Edit store';
            }
            else {
                return `${this.store.name} store`;
            }
        },
        pageSubtitle() {
            if( _st.isNUE( this.storeId ) ) {
                return 'Create a new store to be used within the LFA Rental App';
            }
            else {
                return 'Edit all the details of the store';
            }
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            saveStore: 'stores/saveStore'
        }),
        async init() {
            try {
                this.cleanStoreObject();
                this.loadObjects();

                if( _st.isNUE( this.storeId ) || this.storeId == 'new' ) {
                    return;
                }

                let api = new API();
            
                this.loading = true;
                let res = await api.get(`/admin/store/${this.storeId}`);
                this.loading = false;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return false;
                }

                if( _st.isNUE( res.data.data.id ) ) {
                    this.$router.push('/404');
                    return;
                }

                this.store = res.data.data;
                this.store.phone = _st.toPhoneNumber( this.store.phone );
                this.store.cel = _st.toPhoneNumber( this.store.cel );

                this.$refs.history.getHistory();
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        async loadObjects() {
            let api = new API() ;

            try {
                this.loading = true;
                let res = await api.get('/admin/store-type');
                this.loading = false;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return false;
                }

                this.storeTypes = res.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        cleanStoreObject() {
            this.store = {
                id: 0,
                name: '',
                dateCreated: null,
                typeId: 0,
                type: '',
                active: false,
                terminalQty: 0,
                phone: '',
                email: '',
                cel: '',
                tax: 0.115,
                defaultPickupTime: null,
                wintuxStoreId: '',
                codesToEmail: [],
                codesToPhone: []
            }
        },
        async save() {
            if( !this.$refs.form.validate() )
                return;

            // let api = new API();

            try {
                this.saving = true;
                let res = await this.saveStore( this.store );
                // let res = null;
                
                // if( _st.isNUE( this.storeId ) || _st.isNUE( this.store.id ) ) {
                //     res = await api.post('/admin/store', this.store);
                // }
                // else {
                //     res = await api.put('/admin/store', this.store);
                // }

                this.saving = false;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return false;
                }

                if( _st.isNUE( this.storeId ) || _st.isNUE( this.store.id ) ) {
                    this.$router.push(`/stores/${res.data.data}`);
                    return;
                }

                this.store = res.data.data;
                this.store.typeId = this.store.typeId.toString();
                this.store.phone = _st.toPhoneNumber( this.store.phone );
                this.store.cel = _st.toPhoneNumber( this.store.cel );
                this.mxShowMessage( 'Store saved', 'success' );
            }
            catch(error) {
                this.saving = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        closeEmailDialog() {
            this.addEmailDialog = false;
            this.$refs.emailForm.reset();
        },
        closePhoneDialog() {
            this.addPhoneDialog = false;
            this.$refs.phoneForm.reset();
        },
        openEmailWarning( email ) {
            this.emailToBeRemoved = email;
            this.removeEmailDialog = true;
        },
        addToEmailList() {
            if( !this.$refs.emailForm.validate() )
                return;

            this.store.codesToEmail.push( this.newEmail );
            this.closeEmailDialog();
        },
        removeEmail() {
            this.store.codesToEmail.splice( this.store.codesToEmail.indexOf( this.emailToBeRemoved ), 1 );
            this.removeEmailDialog = false;
        },
        openPhoneWarning( phone ) {
            this.phoneToBeRemoved = phone;
            this.removePhoneDialog = true;
        },
        addToPhoneList() {
            if( !this.$refs.phoneForm.validate() )
                return;

            this.store.codesToPhone.push( this.newPhone );
            this.closePhoneDialog();
        },
        removePhone() {
            this.store.codesToPhone.splice( this.store.codesToPhone.indexOf( this.phoneToBeRemoved ), 1 );
            this.removePhoneDialog = false;
        },
        toPhoneNumber( phone ) {
            return _st.toPhoneNumber( phone );
        },
        emailExist( v ) {
            return !this.store.codesToEmail.includes( v ) || 'email already in the list';
        },
        phoneExist( v ) {
            return !this.store.codesToPhone.includes( v ) || 'phone number already in the list';
        }
    },
    components: { History },
    directives: { mask }
}
</script>

<style lang="scss" scoped>

</style>